import React from 'react'
import { Link, ModalRoutingContext } from 'gatsby-plugin-modal-routing'

class ConditionalContact extends React.Component {
	onContactClose = () => {
		document.body.classList.remove('no-scroll')
	}
	render() {
		const { children } = this.props
		return (
			<ModalRoutingContext.Consumer>
				{({ modal, closeTo }) => {
					const closeToSingle = '/'
					return modal ? (
						<div className="container-fluid contact">
							<div className="row">
								<div className="contact-wrapper">
									{children}
									<Link
										className="md-close"
										to={closeTo}
										onClick={this.onContactClose}
									>
										close
									</Link>
								</div>
							</div>
						</div>
					) : (
						<div className="container-fluid contact">
							<div className="row">
								<div className="contact-wrapper">
									{children}
									<Link className="md-close" to={closeToSingle}>
										close
									</Link>
								</div>
							</div>
						</div>
					)
				}}
			</ModalRoutingContext.Consumer>
		)
	}
}

export default ConditionalContact
