import React from 'react'
import SEO from '../components/seo'
import ConditionalLayout from '../components/conditionalContact'

const SayHello = () => (
	<ConditionalLayout>
		<SEO title="Contact" keywords={[`contact`, `hello`]} />
		<h1>interested in teaming up</h1>
		<span>or just wanna say hello?</span>
		<p>
			I’m still not sure what kind of message will go here, but I’ll figure
			something out later :D
		</p>
		<a className="email" href="mailto:example@gmail.com">
			example@gmail.com
		</a>
	</ConditionalLayout>
)

export default SayHello
